import React, {useState} from 'react';
import { useUiTranslationQuery } from '../hooks/useUiTranslationQuery';
import ButtonImage from './buttonImage';

const ShareButton = ({language}) => {
    const [showToast, setShowToast] = useState(false);

    const translation = useUiTranslationQuery();
    const translationText = translation.find(
      (translation) => translation.frontmatter.language === language
    ).frontmatter;


    const copyTextToClipboard = async () => {
      const text = window.location.href;
      if (navigator.clipboard) {
        // Versuche, die Clipboard API zu verwenden
        try {
          await navigator.clipboard.writeText(text);
          // console.log("Text wurde in die Zwischenablage kopiert.");
          setShowToast(true); // Zeigt eine Erfolgsmeldung
          setTimeout(() => setShowToast(false), 3000); // Verbirgt die Meldung nach 3 Sekunden
        } catch (err) {
          // console.error("Fehler beim Kopieren mit Clipboard API: ", err);
          fallbackCopyTextToClipboard(text); // Führe den Fallback durch, falls Clipboard API fehlschlägt
        }
      } else {
        // Fallback, wenn Clipboard API nicht verfügbar ist
        fallbackCopyTextToClipboard(text);
      }
    };
    
    const fallbackCopyTextToClipboard = (text) => {
      // Erstelle ein neues temporäres Input-Element
      const tempInput = document.createElement("input");
      document.body.appendChild(tempInput);
      tempInput.value = text;
      tempInput.select();
      tempInput.setSelectionRange(0, 99999); // Für iOS-Geräte notwendig
      
      try {
        document.execCommand('copy');
        // console.log("Fallback: Text wurde in die Zwischenablage kopiert.");
        setShowToast(true); // Optional: Zeige eine Benachrichtigung
        setTimeout(() => setShowToast(false), 3000); // Verbirgt die Benachrichtigung nach 3 Sekunden
      } catch (err) {
        // console.error('Fallback: Fehler beim Kopieren in die Zwischenablage', err);
      }
      
      document.body.removeChild(tempInput);
    };

  const shareOnTwitter = () => {
    const tweetText = "" + document.title + " ";
    const tweetUrl = window.location.href;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}${encodeURIComponent(tweetUrl)}`;
    window.open(twitterUrl, '_blank');
  };

  return (
    <div>
    <button onClick={copyTextToClipboard} className="flex-shrink justify-end min-w-0 max-w-[55px] md:max-w-[65px] cursor-pointer p-1 m-0 h-full ">
      <ButtonImage category="share" alt="Share" />
    </button>
    {showToast && (
        <div
        className="fixed top-1/3 left-1/3 transform -translate-x-1/2 -translate-y-1/2 bg-teal-400 text-white py-2 px-4 rounded opacity-0 animate-fadeSlideUp"

        role="alert"
      >
        {translationText.copied_2_clipboard}
      </div>
    )}
    <button onClick={shareOnTwitter} className="flex-shrink justify-end min-w-0 max-w-[55px] md:max-w-[65px] cursor-pointer p-1 m-0 h-full ">
      <ButtonImage category="twitter" alt="Share" />
    </button>
    </div>
  );
};

export default ShareButton;