import React from 'react';
import { useUiTranslationQuery } from '../hooks/useUiTranslationQuery';
import ButtonImage from './buttonImage';

const FeedbackButton = ({language, game}) => {

    const translation = useUiTranslationQuery();
    const translationText = translation.find(
      (translation) => translation.frontmatter.language === language
    ).frontmatter;

  const feedbackClicked = () => {
    const user = "feedback";
    const host = "overrules.mobi";
    const email = `${user}@${host}`;
    
    const subject = encodeURIComponent(translationText.feedback + ": " + game); // Stelle sicher, dass der Betreff URL-kodiert ist
  
    // Füge den Betreff zur Mailto-URL hinzu
    window.location.href = `mailto:${email}?subject=${subject}`;
  };

  return (
    <div className="flex items-center"> 
    <button onClick={feedbackClicked} className="flex-shrink justify-end min-w-0 max-w-[55px] md:max-w-[65px] cursor-pointer p-1 m-0 h-full ">
      <ButtonImage category="feedback" alt="Feedback" />
    </button>
    <div className="text-sm pl-4"> 
          {translationText.feedback_zusatz }
    </div>
    </div>
  );
};

export default FeedbackButton;