import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useImagesQuery } from "../hooks/useImagesQuery";
import { Link } from "gatsby";
import Layout from "../components/layout";
import GameDetailTable from "../components/gameDetailTable";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";
import CategoryImage from "../components/buttonImage";
import FeedbackButton from "../components/feedbackButton";
import PreviewImages from "../components/previewImages";
import ShareButton from "../components/shareButton";
import {Helmet} from "react-helmet";

// die Daten in pageContext kommen aus gatsby-node-gamepage.js aus den .md Files im /content/games/[spielname]
const GamePage = ({ pageContext }) => { 
  
  const {
    name,
    language,
    folder,
    cover,
    html,
    info,
    affiliate,
    preview_images,
  } = pageContext;

  const gameImages = useImagesQuery();
  const pfad = folder + "/images/" + cover;

  //console.log("Images: ", preview_images);

  const gameImage = gameImages.find((image) => image.relativePath === pfad);

  const translation = useUiTranslationQuery();

  const translationText = translation.find(
    (translation) => translation.frontmatter.language === language
  ).frontmatter;
  
  const meta_game = translationText.meta_game || "Overrules Game: ";

  const metaDescription = `${meta_game} ${name} `;


  return (
    <Layout language={language} slug={folder} pageTitle={name}>
        <Helmet>
        
        <meta name="description" content={metaDescription} />
      </Helmet>
      {/* <div className="pt-2 text-4xl mb-4 font-bold">{name}</div> */}

      <h1 className="text-4xl sm:text-6xl text-center md:text-left mb-8 font-bold text-blue-500">
        {name}
      </h1>
      <div className="flex flex-col md:flex-row justify-center md:justify-start ">
        <div className="flex justify-center md:justify-start md:w-1/3">
          <GatsbyImage
            image={gameImage.childImageSharp.gatsbyImageData}
            alt={name}
            className="w-2/3 md:w-full z-0"
            loading="lazy"
          />
        </div>
        <div className="flex flex-col justify-center mt-4 md:mt-0 md:ml-10">
        

          
        <Link to={`/${language}/${folder}/rules`}>
        <div 
          className="text-2xl p-2 mt-4 mb-4 font-bold border border-gray-500 bg-gradient-to-br from-yellow-200 to-yellow-300 rounded-full text-center active:scale-95 transform transition duration-300 ease-out" 
          style={{ maxWidth: '20rem' }}
        >
          <div className="flex items-center justify-center space-x-2" style={{ '@media (min-width: 400px) and (max-width: 640px)': { flexDirection: 'row' } }}>
            <div className="flex w-14 animate-playPulse scale-125">
              <CategoryImage category="play_rules" alt="Infos" />
            </div>
            <div className="pr-4">
              {folder === "infos" ? translationText.tutorial : translationText.rules || "Rules"}
            </div>
          </div>
        </div>
      </Link>


          {/* Tutorial hat keine Gamedetails */}
          {folder !== "infos" && <GameDetailTable pageContext={pageContext} language={language} />}
          <ShareButton language={language} />
        </div>
      </div>

      {info && (
        <div className="flex flex-row justify-between w-full text-xl my-3">
          {info}
        </div>
      )}
      
      <FeedbackButton language={language} game={name} />

      {html && (
        <div class="game_text">
        <div 
          className="mb-4 text-1xl pt-4"
          dangerouslySetInnerHTML={{ __html: html }}
        /></div>
      )}
      {affiliate && (
        <div
          className="mb-4 text-2xl "
          
        >
          <a href={affiliate}>Affiliate: {affiliate}</a>
          </div>
      )}

      {preview_images && <PreviewImages pageContext={pageContext} />}
      
      <Link to={`/${language}/`}>
        <div 
          className="text-2xl p-2 mt-24 mb-4 font-bold border border-gray-500 bg-gradient-to-br from-yellow-200 to-yellow-300 rounded-full text-center active:scale-95 transform transition duration-300 ease-out" 
          style={{ maxWidth: '20rem' }}
        >
          {/* <div className="flex items-center justify-center space-x-2" style={{ '@media (min-width: 400px) and (max-width: 640px)': { flexDirection: 'row' } }}>
            <div className="flex w-14 animate-playPulse scale-125">
              <CategoryImage category="play_rules" alt="Infos" />
            </div> */}
            <div className="p-1">
              {"<<   "}{translationText.all_games || "All Games"}
            </div>
          {/* </div> */}
        </div>
      </Link>

    </Layout>
  );
};

export default GamePage;
