import React, { useState, useRef, useEffect } from "react";

const AnimatedImage = ({ src, alt, onMediaTypeChange, showControls = true }) => {
  const [useWebP, setUseWebP] = useState(true);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [showSpeedOptions, setShowSpeedOptions] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1.0); // Standardgeschwindigkeit ist "Human"

  useEffect(() => {
    const checkVideoAvailability = async () => {
      try {
        const response = await fetch(src.replace('.webp', '.webm'), { method: 'HEAD' });
        if (response.ok) {
          setIsVideoAvailable(true);
        } else {
          setIsVideoAvailable(false);
        }
      } catch (error) {
        setIsVideoAvailable(false);
      }
    };

    checkVideoAvailability();
  }, [src]);

  useEffect(() => {
    onMediaTypeChange(!useWebP);
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackSpeed;
    }
  }, [useWebP, playbackSpeed, onMediaTypeChange]);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleSpeedChange = (speed) => {
    setPlaybackSpeed(speed);
    setShowSpeedOptions(false);
  };

  const resetVideo = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    }
  };

  const handleClick = () => {
    if (isVideoAvailable) {
      setUseWebP(false);
      setIsPlaying(true);
    }
  };

  const handleError = () => {
    setUseWebP(true);
    setIsVideoAvailable(false);
  };

  const handleLoadedMetadata = () => {
    setUseWebP(false);
  };

  const speedIcon = (speed) => {
    switch (speed) {
      case 0.2:
        return <svg><path d="m4.2217 17.544c-1.8694-3.2315 0.045059-8.5535 3.1882-9.4086 3.7974-1.0331 8.1547-0.081901 9.5201 6.2427 0.62432-3.9257 1.7479-3.9616 2.3856-4.2807 0.2946-0.51658 0.26885-2.4065 0.22295-3.6118-0.50985-0.28803-0.17043-0.62563 0.04459-0.64656 0.19916-0.019394 0.53554 0.34802 0.15607 0.60197 0.0819 1.3372 0.06539 2.2729 0.04459 3.7233 0.20572-0.01399 0.30716 0.15818 0.57968-0.04459 0.52762-1.1811 0.57216-2.3797 0.9364-3.5784-0.37963-0.39092-0.04638-0.74612 0.24525-0.7023 0.34802 0.052294 0.45317 0.44131-0.0223 0.80263-0.10577 1.1756-0.40884 1.9669-0.53509 3.5004 0.5862 0.56762 1.27 0.79434 0.91411 2.2072-0.16924 0.67192-0.75961 0.86368-1.1594 1.4492-0.6026 0.88264 0.31471 1.9387-1.3154 3.9909-1.1538 1.4524-3.2566 1.8986-4.9496 1.8282-5.4747-0.22762-7.2787-0.31929-12.463-0.53509-0.40159-0.016716-1.0056-0.37254-0.89181-0.75804 0.13754-0.4661 0.83957-0.77085 1.3154-0.86952 0.52394-0.10864 1.1948 0.27016 1.7836 0.08918z" stop-color="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width=".20179"/></svg>;
         // Snail
      case 0.5:
        return <svg><g transform="translate(-22.293 .076739)"><path d="m26.496 13.892c0.85203-0.89941 1.448-2.4132 2.5156-3.3125 1.4453-1.2174 3.0744-1.649 4.6875-1.4062 1.9527 0.29386 2.9639 0.8144 4.9219 3.2969 0.70338 0.8918 1.0946 0.29009 1.1727 0.70708 0.07023 0.37499-0.1272 0.57749 0.03047 0.51167 1.2016-0.50156 2.3699-1.1262 3.286-1.0126 0.47603 0.05901 0.75797 0.39262 0.99526 0.80949 0.18925 0.33248-0.16432 1.1794-0.86444 1.0936-2.0479-0.25112-2.7557 0.97737-3.9848 1.4064-0.77602 0.27088 1.2455 0.70344 2.3281 0.8125 0.47995 0.04835-0.51443 0.92884-0.97333 1.0991-0.37432 0.13888-1.8846-1.3956-2.8023-1.5139-0.3721-0.04798-0.51346 0.19448-0.64076 0.41478-0.15135 0.2619-0.15297 0.46543-0.09659 0.76262 0.05865 0.30915 0.62316 0.62654 0.48454 0.96434-0.16596 0.40443-0.81022 0.43669-1.2464 0.40704-0.50725-0.03449-1.2278-0.15636-1.1679-0.63399 0.06438-0.51331 0.64154-1.65 0.13824-1.6501-1.153-0.1136-2.4025-0.07541-3.0888-0.02177-0.28205 0.01508-0.1168 0.84375-0.1168 0.84375s1.0288 0.29594 0.95865 0.64157c-0.12681 0.6244-0.29144 0.72443-0.60938 0.73438-0.52571 0.01645-1.3181 0.02911-1.5919-0.16351-0.58879-0.41423-0.15063-1.6232-0.67377-2.1177-0.14045-0.13277-0.56802-0.1572-0.56802-0.1572s-0.33618 1.018-1.2891 1.5547c-0.82391 0.46404-1.0461-0.08496-1.1484-0.5-0.08013-0.32511-0.0083-0.91822 0.17969-1.1953 0.20876-0.30775 1.1148-0.34894 0.96094-0.6875-0.10401-0.22888-0.9977-0.47884-2.2578-0.75781-0.79837-0.17675-1.4267-0.78649-0.80469-0.60156 0.70294 0.209 0.77701 0.20233 1.6094 0.26562 0.22803 0.01734-0.50103-0.42772-0.34375-0.59375z"  stop-color="#000000" /></g></svg>; 
        // Turtle
      case 1.0:
      default:
        //return <path d="M5 12h14" />; // Human
        return <svg><circle cx="12.101" cy="4.8158" r="2.3062" /><path d="m10.968 8.3361 3.0658-0.10174 0.10707 5.0567-0.08672 1.2106-3.0658-0.0407 0.24783-2.4562z"  stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4"/><g fill="none"  stroke-linecap="round" stroke-linejoin="round"><path d="m11.056 8.4921-2.1434 1.6957 0.4748 3.3914" stop-color="#000000" stroke-width="1.8"/><path d="m14.068 8.4242 0.84107 3.0658 2.6589 1.4922" stop-color="#000000" stroke-width="1.7"/><path d="m11.395 14.841-1.0581 2.7945-2.5503 1.9399" stop-color="#000000" stroke-width="2.3"/><path d="m13.606 14.162 1.5058 3.5678 1.0174 3.0116" stop-color="#000000" stroke-width="2.2"/></g></svg>
    }
  };

  const renderMedia = () => {
    if (!useWebP) {
      return (
        <>
          <video
            ref={videoRef}
            src={src.replace('.webp', '.webm')}
            alt={alt}
            loop
            autoPlay
            muted
            onLoadedMetadata={handleLoadedMetadata}
            onError={handleError}
            className="transition-all duration-1000 ease-in-out mx-auto w-full md:w-2/3 lg:w-1/2 h-auto"
          />
          {showControls && (
            <>
              <button onClick={resetVideo} className="bg-gray-800 p-1 rounded-full text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300">
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="m17.7 6.5173-6.8706 5.4156 6.8706 5.4156h0.09239l-0.02719-5.4156 0.02719-5.4156z" fill="#f2f2f2" stroke="#f4f4f4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.9399" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                  <rect x="5.713" y="5.0417" width="2.0032" height="13.859" fill="none" stroke="#f3f3f3" stroke-linejoin="round" stroke-width="2.0437" />
                </svg>
              </button>
              <button onClick={togglePlayPause} className="absolute right-2 bottom-2 bg-gray-800 p-1 rounded-full text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300">
                {isPlaying ? (
                  // Pause icon
                  <svg className="w-6 h-6" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <rect x="7.6603" y="5.5692" width="2.0032" height="13.859" fill="none" stroke="#f3f3f3" stroke-linejoin="round" stroke-width="2.0437" />
                    <rect x="13.953" y="5.5692" width="2.0032" height="13.859" fill="none" stroke="#f3f3f3" stroke-linejoin="round" stroke-width="2.0437" />
                  </svg>
                ) : (
                  // Play icon
                  <svg fill="none" stroke="currentColor" className="w-6 h-6" version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="m9.7895 17.195 6.8706-5.4156-6.8706-5.4157h-0.092388l0.027191 5.4157-0.027191 5.4156z" fill="#f2f2f2" stroke="#f4f4f4" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.9399" />
                  </svg>
                )}
              </button>
              <button onClick={() => setShowSpeedOptions(!showSpeedOptions)} className="absolute right-12 bottom-2 bg-gray-800 p-1 rounded-full text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-300">
                <svg className="w-6 h-6" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  {speedIcon(playbackSpeed)}
                </svg>
              </button>
              {showSpeedOptions && (
                <div className="absolute right-12 bottom-10 bg-white rounded-lg shadow-lg p-2 text-black z-50">
                  {[0.2, 0.5, 1.0].map((speed) => (
                    <button key={speed} onClick={() => handleSpeedChange(speed)} className="block text-sm px-4 py-2 hover:bg-gray-100">
                      <svg className="w-4 h-4 inline-block mr-2" fill="currentColor" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        {speedIcon(speed)}
                      </svg>
                      {speed}x
                    </button>
                  ))}
                </div>
              )}
            </>
          )}
        </>
      );
    } else {
      return (
        <img
          loading="lazy"
          src={src}
          alt={alt}
          onClick={handleClick}
          className="transition-all duration-1000 ease-in-out mx-auto w-full md:w-2/3 lg:w-1/2 h-auto cursor-pointer"
        />
      );
    }
  };

  return (
    <div className="relative">
      {renderMedia()}
    </div>
  );
};

export default AnimatedImage;
