import React from "react";
import GameDetailTableRow from "./gameDetailTableRow";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";
import AnimatedImage from "./animatedImage";
const PreviewImages = ({ pageContext }) => {
  const {
    name,
    language,
    folder,
    cover,
    html,
    info,
    affiliate,
    preview_images,
  } = pageContext;

  const translation = useUiTranslationQuery();
  if (!translation) return <div> </div>;
  const translationText = translation.find(
    (translation) => translation.frontmatter.language === language
  ).frontmatter;
  const setIsVideo = () => {};
  // const renderPlayerText = (players_min, players_max, translationText) => {
  //   if (players_max && players_max !== players_min) {
  //     return `${players_min} - ${players_max} ${translationText.players}`;
  //   }
  //   return `${players_min} ${translationText.players}`;
  // };

  //translationText kommt von hier: /hooks/useUiTranslationQuery.js
  return (
    <div>
      <div className="mb-4 text-1xl pt-4">{translationText.preview} </div>

      {/* <GameDetailTableRow image="players" alt="Players" text={renderPlayerText(players_min, players_max, translationText)} /> */}
      <div className="flex flex-row justify-between w-full text-xl my-3">
        {preview_images.map((image) => {
          const pfad = "/images/" + folder + "/" + image;
          // const gameImage = gameImages.find(
          //   (image) => image.relativePath === pfad
          // );
          return (
            <dev>
              <AnimatedImage src={pfad} alt={pfad} showSelf={true} onMediaTypeChange={setIsVideo}  showControls={false} />
            </dev>
          );
        })}
      </div>
    </div>
  );
};

export default PreviewImages;
