
import React from "react";
import GameDetailTableRow from "./gameDetailTableRow";
import { useUiTranslationQuery } from "../hooks/useUiTranslationQuery";
const GameDetailTable = ({ pageContext, language }) => {
  
  const {
    age,
    dur_from,
    // dur_to,
    players_min,
    players_max,
    // author,
  } = pageContext;

  const translation = useUiTranslationQuery();
  if (!translation) return <div> </div>;
  const translationText = translation.find(translation => translation.frontmatter.language === language).frontmatter;

  const renderPlayerText = (players_min, players_max, translationText) => {
    if (players_max && players_max !== players_min) {
      return `${players_min} - ${players_max} ${translationText.players}`;
    }
    return `${players_min} ${translationText.players}`;
  };

//translationText kommt von hier: /hooks/useUiTranslationQuery.js
  return (
    <div>
   {/* <GameDetailTableRow image="players" alt="Players" text={`${players_min}${(players_max && players_max != players_min) && " - " + players_max + " " + translationText.players}`} /> */}
   <GameDetailTableRow image="players" alt="Players" text={renderPlayerText(players_min, players_max, translationText)} />
   <GameDetailTableRow image="age" alt="Age" text={`${age + "+ " + translationText.years}`} />
   <GameDetailTableRow image="duration" alt="Dur" text={`${dur_from + " " + translationText.minutes}`} />
   {/* <GameDetailTableRow image="swordshield.svg" alt="Dur" text={`${year}`} />
   {author && <GameDetailTableRow image="swordshield.svg" alt="Dur" text={`${author}`} />} */}
   </div>
  );
};

export default GameDetailTable;
