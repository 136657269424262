import React from "react";

// import { useSVGImagesQuery } from "../hooks/useSVGImagesQuery";
import CategoryImage from "./buttonImage";


const GameDetailTableRow = ({ image, alt, text }) => {
    // const uiImages = useSVGImagesQuery();
    // const svgImage = uiImages.find(svg_image => svg_image.relativePath === image);


    return(
        <div className="flex items-center">
        <div className="flex mr-4 w-12">
          <CategoryImage category={image} alt={alt} />
          
          {/* <img src={svgImage.publicURL} alt={alt} className="" width={50} /> */}
        </div>
        <div className="flex-1">
          {text}
        </div>
      </div>
    );
}

export default GameDetailTableRow;